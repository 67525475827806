import Vue from 'vue'
import Router from 'vue-router'
// import menus from '@/config/menu-config'


Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/login'


    },
    {
        path: `/login`,
        name: 'login',
        component: () => import(`@/components/user/login`)

    },
    {
        path: `/mains`,
        name: 'mains',
        component: () => import(`@/components/mains`)

    },
    {
        path: `/mainxx`,
        name: 'mainxx',
        component: () => import(`@/components/mainxx`),
        children:[
            {
                path:'/company',
                name:'company',
                component:() =>import(`@/components/company`)
            },
            {
                path:'/busniessGroup',
                name:'busniessGroup',
                component:() =>import(`@/components/busniessGroup`)
            },
            {
                path:'/analysis',
                name:'analysis',
                component:() =>import(`@/components/analysis`)
            },
            {
                path:'/busniess',
                name:'busniess',
                component:() =>import(`@/components/busniess`)
            },
            {
                path:'/customers',
                name:'customers',
                component:() =>import(`@/components/customers`)
            },
            {
                path:'/apps',
                name:'apps',
                component:() =>import(`@/components/apps`)
            },
            {
                path:'/accountCustomer',
                name:'accountCustomer',
                component:() =>import(`@/components/accountCustomer`)
            },

            {
                path:'/ads',
                name:'ads',
                component:() =>import(`@/components/ads`)
            },
            {
                path:'/pd',
                name:'pd',
                component:() =>import(`@/components/pd`)
            },
            {
                path:'/appProfit',
                name:'appProfit',
                component:() =>import(`@/components/appProfit`)
            },

            {
                path:'/sysmenu',
                name:'sysmenu',
                component:() =>import(`@/components/sys/sysmenu`)
            },
            {
                path:'/role',
                name:'role',
                component:() =>import(`@/components/sys/role`)
            },
            {
                path:'/user',
                name:'user',
                component:() =>import(`@/components/sys/user`)
            },
            {
                path:'/welcome',
                name:'welcome',
                component:() =>import(`@/components/welcome`)
            },
            {
                path:'/adtypes',
                name:'adtypes',
                component:() =>import(`@/components/adver/adtypes`)
            },
            {
                path:'/adtips',
                name:'adtips',
                component:() =>import(`@/components/adver/adtips`)
            },
            {
                path:'/adlist',
                name:'adlist',
                component:() =>import(`@/components/application/adlist`)
            },
            {
                path:'/category',
                name:'category',
                component:() =>import(`@/components/application/category`)
            },
            {
                path:'/accountinfo',
                name:'accountinfo',
                component:() =>import(`@/components/account/accountinfo`)
            },
            {
                path:'/balance',
                name:'balance',
                component:() =>import(`@/components/account/balance`)
            },
            {
                path:'/adapply',
                name:'adapply',
                component:() =>import(`@/components/account/adapply`)
            }
        ]
    },


]

// menus.forEach((item) => {
//     item.children.forEach((children) => {
//         routes.push({
//             path: `/${children.componentName}`,
//             name: children.componentName,
//             component: () => import(`@/components/${children.componentName}`)
//         })
//     })
// })


// routes.push({
//     path: `/mains`,
//     name: 'mains',
//     component: () => import(`@/components/mains`)
// })

export default new Router({ routes })
