import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import App from './App.vue'
// import login from './components/user/login'
import router from './router'

import main from './components/mains/index'
Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

new Vue({
  el: '#app',
  router,
  render: h => h(main)
})


